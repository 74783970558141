import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import Routing from './Routes/Routing';
import "./CSS/common.css";

function App() {
  return (
    <div className="background-black">
      <Routing/>
    </div>
  );
}

export default App;
