import React,{useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer,toast } from "react-toastify";
import menubg from "../Data/assets/menubg-2.png";
import btn_play from "../Data/assets/btn_play.png";
import btn_help from "../Data/assets/btn_help.png";
import btn_terms from "../Data/assets/btn_terms.png";
import btn_soundon from "../Data/assets/btn_soundon.png";
import btn_soundoff from "../Data/assets/btn_soundoff.png";
import startgame from "../Data/assets/media/startgame.mp3";
import whatsapp from "../Data/WhatsApp.jpg";
import { checkUser } from "../API/api";
// import CoinsMotion from "../Components/CoinsMotion";
import "../CSS/home.css";
import "../CSS/motion.css";
import axios from "axios";

const Home = () => {

        const navigate=useNavigate();

        useEffect(()=>{
          document.title="Gamewin||Game";
          readQueryParam();
          // eslint-disable-next-line
        },[]);    

        const[data,setData]=useState({});

        let uid;
        let gid;
        let svcId;

        const readQueryParam=()=>{
          let queryParam=new URLSearchParams(window.location.search);

          uid=queryParam.get("uid");
          // console.log("uid is ",uid);
          // uid=995596
          localStorage.setItem("uid",uid);
         
          gid=queryParam.get("gid");
          // console.log("gid is ",gid);
// gid=3
          localStorage.setItem("gid",gid);


          svcId=queryParam.get("svcId");
          // console.log("svcId is ",svcId);
          // svcId=737;
          localStorage.setItem("svcId",svcId);

          setData({...data,uid:uid,gid:gid});
          // console.log(data);
        }

        // useEffect(()=>{
        //   console.log("inside useEffect ",data);
        // },[data])

        let myAudio = document.getElementById("myAudio");
        let isPlaying = false;

        const togglePlay=()=>{
            isPlaying ? myAudio.pause() : myAudio.play();
        };

        let myImg=document.getElementById('myImg') ;

        if(myAudio!=null)
        {
            myAudio.onplaying=()=>{
                isPlaying = true;
                if(myImg!=null)
                {
                    document.getElementById('myImg').src=btn_soundon;
                }
            };
            myAudio.onpause=()=>{
                isPlaying = false;
                if(myImg!=null)
                {
                    document.getElementById('myImg').src=btn_soundoff;
                }
            };    
        }

        const handleFaq=()=>{
            navigate("/faq");
        }

        const handleTerms=()=>{
            navigate("/terms");
        }

        const handlePlay=()=>{

            //Check Chance when click on play button
              hitOnApi();
        }

        const hitOnApi=()=>{
          // console.log("data inside hitOnApi is ",data);

         let request={"uid":data.uid,"gid":data.gid};

        //  let request = {"uid":995596,"gid":3};

         
          axios.post(`${checkUser}`,request)
          .then(
            (response)=>{
              console.log(response.data);
              if(response.data.response==='User Can Play')
              {
                console.log("User Can Play");
                navigate("/category");
              }
              else if(response.data.response==='No More Chances')
              {
                console.log("User Cannot Play");
                toast.error("No More Chances");
                sendToPayMore();
              }
              else if(response.data.response==='Your Billing is Pending')
              {
                console.log("Billing is Pending")
                toast.error("Billing is Pending");
              }
              else if(response.data.response==='You are Not Subscribed')
              {
                console.log("Not Subscribed");
                toast.error("Not Subscribed");
              }
            },
            (error)=>{
              console.log(error);
              toast.error("Something Went Wrong");
            }
          )
        }

        const sendToPayMore=()=>{
          setTimeout(()=>{
            navigate("/more");
          },2000);
        }

        const handleWhatsapp=()=>{
          window.location.href="https://wa.me/+2349160000453";
        }
        

  return (
    <div>
      <ToastContainer/>
      <section className="home-main-sec">

      {/* <CoinsMotion/> */}

        <div className="home-try">
          <div className="home-logo-1">
            <img src={menubg} alt="ImgSrc"/>
          </div>

          <div className="home-cmplt-data">
            <div className="home-btn-1">
              <button className="btn-bg-transparent" onClick={()=>{
                handlePlay();
              }}>
                <img src={btn_play} alt="ImgSrc"/>
              </button>
            </div>

            <div className="play-name">
              <p style={{color:"white"}}>Play</p>
            </div>

            {/* <div className="home-winner-btn">
              <a href="./winner-pg-1.html">
                <button type="button">Winners</button>
              </a>
            </div> */}
            <div className="home-btns">
              <div className="home-btn-3">
                <button className="btn-bg-transparent" onClick={()=>{
                    handleFaq();
                }}>
                  <img src={btn_help} alt="ImgSrc"/>
                </button>
              </div>

              <div className="home-btn-3">
                <button className="btn-bg-transparent" onClick={()=>{
                    handleTerms();
                }}>
                  <img src={btn_terms} alt="ImgSrc"/>
                </button>
              </div>

              <div className="home-btn-3">
                <button className="btn-bg-transparent" onClick={()=>{
                    togglePlay();
                }}>
                  <img src={btn_soundon} id="myImg" alt="ImgSrc"/>
                  <div className="home-audio-1">
                    <audio id="myAudio" controls autoPlay>
                      <source
                        src={startgame}
                        type="audio/mpeg"
                      />
                      Your browser does not support the audio element.
                    </audio>
                  </div>
                </button>
              </div>
              <div>
                <button className="btn-bg-transparent"
                  onClick={()=>{
                    handleWhatsapp();
                  }}>
                  <img src={whatsapp} alt="ImgSrc" height="60px" width="60px"/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Home;
