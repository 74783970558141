import React from "react";
import { useNavigate } from "react-router-dom";
import menubg from "../Data/assets/menubg-2.png";
import btn_textback2 from "../Data/assets/btn_textback2.png";
import btn_sportssel from "../Data/assets/btn_sportssel.png";
import btn_currentaffairssel from "../Data/assets/btn_currentaffairssel.png";
import "../CSS/category.css";

const Category = () => {

    const navigate=useNavigate();

    let uid;let gid;let svcId;
    const getLocalStorage = () => {
      uid = localStorage.getItem("uid");
      // console.log("uid from localStorage is ", uid);
  
      gid = localStorage.getItem("gid");
      // console.log("gid from localStorage is ", gid);  

      svcId=localStorage.getItem("svcId");
      console.log("svcId is ",svcId);
   };  

    const handleBack=()=>{
        getLocalStorage();
        navigate(`/?uid=${uid}&gid=${gid}&svcId=${svcId}`);
    }

    const handleSports=()=>{
        localStorage.setItem("category","Sports");
        navigate("/play");
    }

    const handleCurrentAffairs=()=>{
        localStorage.setItem("category","Current Affairs");
        navigate("/play");
    }

  return (
    <div>
      <section className="category-main-sec-5">
        <div className="category-logo-1">
          <img src={menubg} alt="ImgSrc"/>
        </div>

        <div className="category-back-5">
          <button className="btn-bg-transparent" onClick={()=>{
            handleBack();
          }}>
            <img src={btn_textback2} alt="ImgSrc"/>
          </button>
        </div>
        
        <div className="category-ques-bg  category-menu-1">
        
          <div className="category-ques-bg-1">
            <button className="btn-bg-transparent" onClick={()=>{
                handleCurrentAffairs();
            }}>
              <img src={btn_currentaffairssel} alt="ImgSrc" />
            </button>
          </div>

          <div className="category-ques-bg-1">
            <button className="btn-bg-transparent" 
            onClick={()=>{
                handleSports();
            }}>
              <img src={btn_sportssel} alt="ImgSrc"/>
            </button>
          </div>

        </div>
      </section>
    </div>
  );
};
export default Category;
