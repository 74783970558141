import React from "react";
import { BrowserRouter,Routes,Route } from "react-router-dom";
import Category from "../Pages/Category";
import Faq from "../Pages/Faq";
import Home from "../Pages/Home";
import More from "../Pages/More";
import Play from "../Pages/Play";
// import Redeem from "../Pages/Redeem";
import Terms from "../Pages/Terms";

const Routing=()=>{
    return(
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home/>} exact></Route>
                    <Route path="/faq" element={<Faq/>} exact></Route>
                    <Route path="/terms" element={<Terms/>} exact></Route>
                    <Route path="/category" element={<Category/>} exact></Route>
                    <Route path="/play" element={<Play/>} exact></Route>
                    <Route path="/more" element={<More/>} exact></Route>
                    {/* <Route path="/redeem" element={<Redeem/>} exact></Route> */}
                </Routes>
            </BrowserRouter>
        </>
    );
}
export default Routing;