import React, { useEffect, useState } from "react";
import axios from "axios";
import { options } from "../Data/LoaderData";
import { useNavigate } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import btn_home from "../Data/assets/btn_home.png";
import btn_soundon from "../Data/assets/btn_soundon.png";
import startgame from "../Data/assets/media/startgame.mp3";
import { checkUser } from "../API/api";
// import optionsbg from "../Data/assets/optionsbg.png";
import btn_soundoff from "../Data/assets/btn_soundoff.png";
import { getQuestion } from "../API/api";
import "../CSS/play.css";
import { sendRedeemData } from "../API/api";
import { reduceChance,redeemUser } from "../API/api";
import levelbg_1 from "../Data/assets/levelbg-1.png";
import btn_textback2 from "../Data/assets/btn_textback2.png";
import btn_startsel from "../Data/assets/btn_startsel.png";
import "../CSS/redeem.css";

const Play = () => {

    //Variable to hold mcq data
    let data = {};

  //------------------------------------------------------------------------------------//

  //Check User & his chances

  const hitOnApiToCheck=()=>{
    // //console.log("data inside hitOnApi is ",data);

    let uid=localStorage.getItem("uid");
    let gid=localStorage.getItem("gid");
    let svcId=localStorage.getItem("svcId");

    let request={"uid": uid,"gid":gid};
    // let request={"uid": 995596,"gid":3};
    
    // console.log("request ",request);
    console.log("Checking");

    axios.post(`${checkUser}`,request)
    .then(
      (response)=>{
        //console.log(response.data);
        setDivState('none');
        if(response.data.response==='User Can Play')
        {
          console.log("User Can Play");
          disablingOptions();
          fetchQuestion();
        }
        else if(response.data.response==='No More Chances')
        {
          console.log("User Cannot Play");
          toast.error("No More Chances");
          navigate(`/?uid=${uid}&gid=${gid}&svcId=${svcId}`);
        }
        else if(response.data.response==='Your Billing is Pending')
        {
          console.log("Billing is Pending")
          toast.error("Billing is Pending");
          navigate(`/?uid=${uid}&gid=${gid}&svcId=${svcId}`);
        }
        else if(response.data.response==='You are Not Subscribed')
        {
          console.log("Not Subscribed");
          toast.error("Not Subscribed");
          navigate(`/?uid=${uid}&gid=${gid}&svcId=${svcId}`);
        }
      },
      (error)=>{
        setDivState('none');
        console.log(error);
        toast.error("Something Went Wrong");
        navigate(`/?uid=${uid}&gid=${gid}&svcId=${svcId}`);
      }
    )
  }

  //Loader
    //Loader Stuff
    let Loader=require('react-loader');

    //Hook to store starting div state(loading div)
    const[divState,setDivState]=useState('block');

  //-----------------------------------Redeem------------------------------------------//

  // Modal open state
  const [modal, setModal]=useState(false);

  // Toggle for Modal
  const toggle=()=>setModal(!modal);

  const handleFiveRedeem=()=>{
    // //console.log("Inside Five Redeem");

    //disabling button
    document.getElementById("five").disabled=true;
    document.getElementById("ten").disabled=true;

    let five_td_amount=document.getElementById("five-td-amount").innerText;
    // //console.log("amount is ",five_td_amount);

    //Showing Message
    // toast.success("Bravo! Your redeemed prize has entered for today's draw. Winners would be contacted after the draw. Thank you.");
    toast.success(`Bravo! You've entered today's draw for ${five_td_amount} prize. Winners would be notified via SMS after the draw. Thank you.`);

    getLocalStorage();

        // Reducing Chance
        hitReduceChance(uid,gid);

        // Hit for Redeem  //userId,gameId,srvcId,coin
        hitOnRedeem(uid,gid,svcId,five_td_amount);

    //then after 3 seconds redirect to home page
    setTimeout(() => {
      navigate(`/?uid=${uid}&gid=${gid}&svcId=${svcId}`);
    }, 3000);

  };

  const handleTenRedeem=()=>{
    // //console.log("Inside Ten Redeem");

    //disabling button
    document.getElementById("five").disabled=true;
    document.getElementById("ten").disabled=true;

    let ten_td_amount=document.getElementById("ten-td-amount").innerText;
    // //console.log("amount is ",ten_td_amount);

    //Showing Message
    // toast.success("Bravo! Your redeemed prize has entered for today's draw. Winners would be contacted after the draw. Thank you.");
    toast.success(`Bravo! You've entered today's draw for ${ten_td_amount} prize. Winners would be notified via SMS after the draw. Thank you.`);

    getLocalStorage();

    //Reducing Chance
    hitReduceChance(uid,gid);

    //Hit for Redeem  //userId,gameId,srvcId,coin
    hitOnRedeem(uid,gid,svcId,ten_td_amount);

    //then after 3 seconds redirect to home page
    setTimeout(() => {
      navigate(`/?uid=${uid}&gid=${gid}&svcId=${svcId}`);
    }, 3000);

  };


  //method to hit on reduce chance api
  const hitReduceChance=(userId,gameId)=>{

    let request={uid:userId,gid:gameId};

    axios.post(`${reduceChance}`,request)
    .then(
      (response)=>{
        // //console.log(response.data);
      },
      (error)=>{
        //console.log(error);
        toast.error("Something Went Wrong");
      }
    )
  }

  //method to hit on reduce chance
  const hitOnRedeem=(userId,gameId,srvcId,coin)=>{
    axios.post(`${redeemUser}userid=${userId}&gameid=${gameId}&coin=${coin}&svc_id=${srvcId}`)
    .then(
      (response)=>{
        console.log(response.data);
      },
      (error)=>{
        //console.log(error);
        toast.error("Something Went Wrong.");
      }
    )
  }


//when user not redeem but continue the game
  const handleContinue=()=>{
    toggle();

    // let getCount=localStorage.getItem("count");
    // //console.log("here count is ",count);
    // //console.log("getCount is ",parseInt(getCount));
    
    // count=parseInt(getCount);
    // //console.log("now count is ",count);

    fetchQuestion();
  };

  const[redeemDataArray,setRedeemDataArray]=useState([]);

  const[fiveVisible,setFiveVisible]=useState('none');
  const[tenVisible,setTenVisible]=useState('none');

  //------------------------------------------JS-----------------------------------------//
  const navigate = useNavigate();

  let myAudio = document.getElementById("myAudio");
  let isPlaying = false;

  function togglePlay() {
    isPlaying ? myAudio.pause() : myAudio.play();
  }

  let myImg = document.getElementById("myImg");
  if (myAudio != null) {
    myAudio.onplaying = function () {
      isPlaying = true;
      if (myImg != null) {
        document.getElementById("myImg").src = btn_soundon;
      }
    };
    myAudio.onpause = function () {
      isPlaying = false;
      if (myImg != null) {
        document.getElementById("myImg").src = btn_soundoff;
      }
    };
  }
  //------------------------------------------JS-----------------------------------------//

  const handleBack = () => {
    getLocalStorage();

    //Reducing Chance
    hitReduceChance(uid,gid);

    navigate(`/?uid=${uid}&gid=${gid}&svcId=${svcId}`);
  };

  let uid;
  let gid;
  let svcId;
  let category;

  //-----------------------------------------State Time Stuff---------------------------//

  const START_SECOND = '10';
  const START_DERATION = 10;

  const [currentSeconds, setSeconds] = useState(START_SECOND);
  // eslint-disable-next-line
  const [isStop, setIsStop] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [duration, setDuration] = useState(START_DERATION);

  const startHandler = () => {
      // //console.log("Start Timer");
      // //console.log(parseInt(START_SECOND, 10));
      setDuration(parseInt(START_SECOND, 10));
      setIsRunning(true);
    };

    const stopHandler = () => {
      // stop timer
      // //console.log("Stoping Timer");
      setIsStop(true);
      setIsRunning(false);
    };

    // eslint-disable-next-line
    const resetHandler = () => {
      setSeconds(START_SECOND);
      setIsRunning(false);
      setIsStop(false);
      setDuration(START_DERATION);
  };

    useEffect(() => {
      // eslint-disable-next-line
      if (isRunning === true) {
        let timer = duration;
        let seconds;
        const interval = setInterval(function () {
          if (--timer <= 0) {
            // resetHandler();
            // //console.log("Time Over");
    
        //Disabling Options
        document.getElementById("a").style.pointerEvents = "none";
        document.getElementById("b").style.pointerEvents = "none";
        document.getElementById("c").style.pointerEvents = "none";
        document.getElementById("d").style.pointerEvents = "none";

        toast.error("Times Up");
        getLocalStorage();

        //Reducing Chance
        hitReduceChance(uid,gid);

        setTimeout(() => {
          navigate(`/?uid=${uid}&gid=${gid}&svcId=${svcId}`);
        }, 1000);

        } else {
          seconds = parseInt(timer % 60, 10);
          seconds = seconds < 10 ? '0' + seconds : seconds;    
          setSeconds(seconds);
        }
      }, 1000);
      return () => clearInterval(interval);
    }// eslint-disable-next-line
  }, [isRunning]);

  //-----------------------------------------State Time Stuff---------------------------//

  //---------------------------------------Timer Stuff-----------------------------------//

  // const[timeState,setTimeState]=useState(5);
  // let time = 5; //  The default time of the timer
  // let timer_id; //    This is used by setInterval function

  // const stopTimer = () => {
  //   //console.log("Stopping Timer");
  //   clearInterval(timer_id);
  //   time=5;
  //   // setTimeState(5);
  // };

  // const generateTime = () => {
  //   // let time=0;
  //   // time=parseInt(timeState);
  //   let second = time % 60;
  //   second = second < 10 ? "0" + second : second;

  //   // setTimeState(second);
  //   document.getElementById("timeDiv").innerHTML = second;
  // };

  // const startTimer = (interval) => {
  //   //console.log("interval is ", interval);
  //   // let time=0;
  //   // time=parseInt(timeState);
  //   timer_id = setInterval(function () {
  //     if (time <= 5 && time > 0) {
  //       time--;
  //       //console.log("time is ",time);
      
  //       // setTimeState(time);
  //       // //console.log("timeState is ",timeState);
  //       generateTime();

  //     } else if (time === 0) {
  //       //console.log("Time Finish");
  //       stopTimer();

  //       //Disabling Options
  //       document.getElementById("a").style.pointerEvents = "none";
  //       document.getElementById("b").style.pointerEvents = "none";
  //       document.getElementById("c").style.pointerEvents = "none";
  //       document.getElementById("d").style.pointerEvents = "none";

  //       toast.error("Times Up");
  //       getLocalStorage();

  //       setTimeout(() => {
  //         navigate(`/?uid=${uid}&gid=${gid}`);
  //       }, 2000);
  //     }
  //   }, interval);
  // };

  //---------------------------------------Timer Stuff-----------------------------------//

  //To run on load - get first question
  useEffect(() => {
    document.title = "Gamewin||Game";
    // //console.log("useEffect Running");
    // getLocalStorage();
    hitOnApiToCheck();
    // eslint-disable-next-line
  }, []);

  //To get uid from localStorage
  const getLocalStorage = () => {
    uid = localStorage.getItem("uid");
    // //console.log("uid from localStorage is ", uid);
    // uid='995596';
    
    gid = localStorage.getItem("gid");
    // //console.log("gid from localStorage is ", gid);
    // gid=3;
    category = localStorage.getItem("category");
    // //console.log("category from localStorage is ", category);

    svcId=localStorage.getItem("svcId");
    // //console.log("svcId is ",svcId);
    // svcId=737;
    if(uid==='null')
    {
      // //console.log("Inside uid is null - so redirecting to home page");
      navigate(`/?uid=${uid}&gid=${gid}&svcId=${svcId}`); 
    }

  };


  //States to hold questions & options
  // let question = "Question";
  // let a = "Option A";
  // let b = "Option B";
  // let c = "Option C";
  // let d = "Option D";
  // let correct = "Correct";

  const[questionState,setQuestionState]=useState("");
  const[aState,setAState]=useState("");
  const[bState,setBState]=useState("");
  const[cState,setCState]=useState("");
  const[dState,setDState]=useState("");
  const[correctState,setCorrectState]=useState("");

  //Method to get question from backend
  const[countState,setCountState]=useState(0);

  const fetchQuestion=()=>{

    let count=0;
    count=countState;
    // //console.log("count is ", count);

    count=count+1;
    setCountState(count);
    // //console.log("countState : ",countState);

    if(count>10)
    {
      toast.success("Game Over");
      getLocalStorage();

        //Reducing Chance
        hitReduceChance(uid,gid);

      setTimeout(() => {
        navigate(`/?uid=${uid}&gid=${gid}&svcId=${svcId}`);
      }, 2000);
    }

    document.getElementById("questionNumber").innerHTML = count;
    getLocalStorage();

    // //console.log("uid in fetching time is ", uid);
    // //console.log("gid in fetching time is ", gid);
    // //console.log("category in fetching time is ", category);

    let request = { uid: uid, category: category };
    axios.post(`${getQuestion}`, request).then(
      (response) => {
        // //console.log(response.data.response);
        // console.log(response.data.response.correct); this is used to show correct option .
        data = response.data.response;
        settingData(data);
      },
      (error) => {
        //console.log(error);
        toast.error("Something Went Wrong");
      }
    );
  };

  const disablingOptions=()=>{
    //Disabling Options
    document.getElementById("a").style.pointerEvents = "none";
    document.getElementById("b").style.pointerEvents = "none";
    document.getElementById("c").style.pointerEvents = "none";
    document.getElementById("d").style.pointerEvents = "none";
  }

  //Method to show question & options in frontend
  const settingData = (data) => {
    // console.log(data.correct);
    // question = data.question;
    // a = data.optionA;
    // b = data.optionB;
    // c = data.optionC;
    // d = data.optionD;
    // correct = data.correct;
    // startTimer(1000);
    startHandler();

    setQuestionState(data.question);
    setAState(data.optionA);
    setBState(data.optionB);
    setCState(data.optionC);
    setDState(data.optionD);
    setCorrectState(data.correct);

    //Enabling Options
    document.getElementById("a").style.pointerEvents = "visible";
    document.getElementById("b").style.pointerEvents = "visible";
    document.getElementById("c").style.pointerEvents = "visible";
    document.getElementById("d").style.pointerEvents = "visible";

    //Setting background colors to normal
    document.getElementById("a").style.background
    ="linear-gradient(to left, #e9b778 0%,#ff5722 60%,#c9a110 110%)";
    document.getElementById("b").style.background
    ="linear-gradient(to left, #e9b778 0%,#ff5722 60%,#c9a110 110%)";
    document.getElementById("c").style.background
    ="linear-gradient(to left, #e9b778 0%,#ff5722 60%,#c9a110 110%)";
    document.getElementById("d").style.background
    ="linear-gradient(to left, #e9b778 0%,#ff5722 60%,#c9a110 110%)";

    //Setting Values
    // document.getElementById("questionDiv").innerHTML = questionState;
    // document.getElementById("a").innerHTML = aState;
    // document.getElementById("b").innerHTML = bState;
    // document.getElementById("c").innerHTML = cState;
    // document.getElementById("d").innerHTML = dState;
  };

  //Method to check answer
  const checkAnswer = (ans, selectedOption) => {
    //Stopping Time Count
    // stopTimer();
    stopHandler();

    //Disabling Options
    document.getElementById("a").style.pointerEvents = "none";
    document.getElementById("b").style.pointerEvents = "none";
    document.getElementById("c").style.pointerEvents = "none";
    document.getElementById("d").style.pointerEvents = "none";

    //console.log("Correct Answer is ", correct);
    // //console.log("Selected Answer is ", ans);

    // //console.log("Correct Answer from state is ",correctState);
    // //console.log("")

    if (correctState === ans) {
      console.log("Correct Answer");
      // swal("Correct Answer");
      document.getElementById(selectedOption).style.background =
        "linear-gradient(to left, #15c818 0%,#72e170 60%,#3e851b 110%)";
      toast.success("Correct Answer");

      //check if user at question 5 or question 10
      if (countState === 5 || countState === 10) {
        //get Redeem Data according to user

        // localStorage.setItem("count",count);

        toggle();

        getLocalStorage();
        let requestData = { uid: uid };

        axios.post(`${sendRedeemData}`, requestData)
        .then(
          (response) => {
            console.log(response.data.response);
            setRedeemDataArray(response.data.response);
          },
          (error) => {
            //console.log(error);
            toast.error("Something Went Wrong");
          }
        );

        if(countState===5)
        {
          //Unhide redeem button on 5
          // document.getElementById("five").style.display="flex";
          setFiveVisible('flex');
          setTenVisible('none');
        }
        else if(countState===10)
        {
          //Unhide redeem button on 5
          // document.getElementById("ten").style.display="flex";
          toast.success("Congratulations!! You Complete the game");
          setFiveVisible('none');
          setTenVisible('flex');
        }

      }
      else{
        //Get Next Question
        setTimeout(() => {
          fetchQuestion();
        }, 2000);
      }

    } else {
      // //console.log("Wrong Answer");
      // swal("Wrong Answer");
      document.getElementById(selectedOption).style.background
      ="linear-gradient(to left, #f35f3b 0%,#e13812 60%,#f79081 110%)";
      toast.error("Wrong Answer, Try Again");
      getLocalStorage();

        //Reducing Chance
        hitReduceChance(uid,gid);

      setTimeout(() => {
        navigate(`/?uid=${uid}&gid=${gid}&svcId=${svcId}`);
      }, 3000);
    }
  };

  return (
    <div>
     <div className="loading-div" style={{display:`${divState}`}}>
        <Loader loaded={false} options={options} className="spinner" />
      </div>
      <ToastContainer />
      <section className="play-main-sec-2">
        <div className="play-btns-4">
          <div className="play-btn-3">
            <button
              className="btn-bg-transparent"
              onClick={() => {
                handleBack();
              }}
            >
              <img src={btn_home} alt="ImgSrc" />
            </button>
          </div>

          <div className="play-btn-5">
            <button
              className="btn-bg-transparent"
              onClick={() => {
                togglePlay();
              }}
            >
              <img src={btn_soundon} alt="ImgSrc" id="myImg" />
              <div className="play-audio-1">
                <audio id="myAudio" controls autoPlay>
                  <source src={startgame} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              </div>
            </button>
          </div>
        </div>

        <div className="play-tm-pg-1">
          <div className="play-ques-no">
            <p>
              <span id="questionNumber">{countState}</span>/10
            </p>
          </div>
          <div className="play-time-1">
            <p>
              Time:<span id="timeDiv">{currentSeconds}</span>
            </p>
          </div>
        </div>
        <div className="play-choose-1">
          <p className="not-select" id="questionDiv">{questionState}</p>
        </div>

        <div className="play-ques-bg" id="id-1">
          <div
            className="play-ques-bg-1 not-select"
            id="a"
            onClick={() => {
              checkAnswer(aState, "a");
            }}
          >{aState}
          </div>

          <div
            className="play-ques-bg-1 not-select"
            id="b"
            onClick={() => {
              checkAnswer(bState, "b");
            }}
          >{bState}
          </div>

          <div
            className="play-ques-bg-1 not-select"
            id="c"
            onClick={() => {
              checkAnswer(cState, "c");
            }}
          >{cState}
          </div>

          <div
            className="play-ques-bg-1 not-select"
            id="d"
            onClick={() => {
              checkAnswer(dState, "d");
            }}
          >{dState}
          </div>
        </div>
      </section>

      {/* //-----------------------------Modal--------------------------------// */}

      <div>
        {/* <Button color="primary" onClick={toggle}>
          Open Modal
        </Button> */}
        <Modal
          isOpen={modal}
          toggle={toggle}
          modalTransition={{ timeout: 2000 }}
        >
          <ModalBody>
            <div>
              <ToastContainer />
              <section className="redeem-main-sec-2">
                <div className="redeem-logo-1">
                  <img src={levelbg_1} alt="ImgSrc" />
                </div>

                <div className="redeem-back-5">
                  <button
                    className="btn-bg-transparent"
                    onClick={() => {
                      handleBack();
                    }}
                  >
                    <img src={btn_textback2} alt="ImgSrc" />
                  </button>
                </div>
                <div className="redeem-table-1">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          {redeemDataArray.length > 0 &&
                            redeemDataArray[0].level}
                        </td>
                        <td id="ten-td-amount">
                          {redeemDataArray.length > 0 &&
                            redeemDataArray[0].amount}
                        </td>
                        <td>
                          <div className="redeem-winner-btn">
                            <button
                              type="button"
                              id="ten"
                              style={{ display:tenVisible}}
                              onClick={() => {
                                handleTenRedeem();
                              }}
                            >
                              Enter the Draw
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {redeemDataArray.length > 0 &&
                            redeemDataArray[1].level}
                        </td>
                        <td>
                          {redeemDataArray.length > 0 &&
                            redeemDataArray[1].amount}
                        </td>
                        <td>
                          <div className="redeem-winner-btn">
                            {/* <button type="button" 
                          id="nine"
                        onClick={()=>{
                            handleRedeem();
                        }}>Redeem</button> */}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {redeemDataArray.length > 0 &&
                            redeemDataArray[2].level}
                        </td>
                        <td>
                          {redeemDataArray.length > 0 &&
                            redeemDataArray[2].amount}
                        </td>
                        <td>
                          <div className="redeem-winner-btn">
                            {/* <button type="button"
                          id="eight"
                      onClick={()=>{
                        handleRedeem();
                      }}>Redeem</button> */}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {redeemDataArray.length > 0 &&
                            redeemDataArray[3].level}
                        </td>
                        <td>
                          {redeemDataArray.length > 0 &&
                            redeemDataArray[3].amount}
                        </td>
                        <td>
                          <div className="redeem-winner-btn">
                            {/* <button type="button"
                        id="seven"
                      onClick={()=>{
                        handleRedeem();
                      }}>Redeem</button> */}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {redeemDataArray.length > 0 &&
                            redeemDataArray[4].level}
                        </td>
                        <td>
                          {redeemDataArray.length > 0 &&
                            redeemDataArray[4].amount}
                        </td>
                        <td>
                          <div className="redeem-winner-btn">
                            {/* <button type="button"
                      id="six"
                      onClick={()=>{
                        handleRedeem();
                      }}>Redeem</button> */}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {redeemDataArray.length > 0 &&
                            redeemDataArray[5].level}
                        </td>
                        <td id="five-td-amount">
                          {redeemDataArray.length > 0 &&
                            redeemDataArray[5].amount}
                        </td>
                        <td>
                          <div className="redeem-winner-btn">
                            <button
                              type="button"
                              id="five"
                              style={{ display:fiveVisible}}
                              onClick={() => {
                                handleFiveRedeem();
                              }}
                            >
                              Enter the Draw
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {redeemDataArray.length > 0 &&
                            redeemDataArray[6].level}
                        </td>
                        <td>
                          {redeemDataArray.length > 0 &&
                            redeemDataArray[6].amount}
                        </td>
                        <td>
                          <div className="redeem-winner-btn">
                            {/* <button type="button"
                      id="four"
                      onClick={()=>{
                        handleRedeem();
                      }}>Redeem</button> */}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {redeemDataArray.length > 0 &&
                            redeemDataArray[7].level}
                        </td>
                        <td>
                          {redeemDataArray.length > 0 &&
                            redeemDataArray[7].amount}
                        </td>
                        <td>
                          <div className="redeem-winner-btn">
                            {/* <button type="button"
                      id="three"
                      onClick={()=>{
                        handleRedeem();
                      }}>Redeem</button> */}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {redeemDataArray.length > 0 &&
                            redeemDataArray[8].level}
                        </td>
                        <td>
                          {redeemDataArray.length > 0 &&
                            redeemDataArray[8].amount}
                        </td>
                        <td>
                          <div className="redeem-winner-btn">
                            {/* <button type="button"
                      id="two"
                      onClick={()=>{
                        handleRedeem();
                      }}>Redeem</button> */}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {redeemDataArray.length > 0 &&
                            redeemDataArray[9].level}
                        </td>
                        <td>
                          {redeemDataArray.length > 0 &&
                            redeemDataArray[9].amount}
                        </td>
                        <td>
                          <div className="redeem-winner-btn">
                            {/* <button type="button"
                      id="one"
                      onClick={()=>{
                        handleRedeem();
                      }}>Redeem</button> */}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="redeem-start-img">
                  <button
                    className="btn-bg-transparent"
                    onClick={() => {
                      handleContinue();
                    }}
                  >
                    <img src={btn_startsel} alt="ImgSrc" />
                  </button>
                </div>
              </section>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
};
export default Play;
