import React, { useEffect, useState } from "react";
import { ToastContainer,toast } from "react-toastify";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import menubg from "../Data/assets/menubg-2.png";
import btn_textback2 from "../Data/assets/btn_textback2.png";
import { getFaq} from "../API/api";
import "../CSS/faq.css";
import axios from "axios";

const Faq = () => {

    const navigate=useNavigate();

    let uid;let gid;let svcId;
    const getLocalStorage = () => {
      uid = localStorage.getItem("uid");
      // console.log("uid from localStorage is ", uid);
  
      gid = localStorage.getItem("gid");
      // console.log("gid from localStorage is ", gid);  

      svcId=localStorage.getItem("svcId");
      // console.log("svcId is ",svcId);
 };  

    const handleBack=()=>{
      getLocalStorage();
        navigate(`/?uid=${uid}&gid=${gid}&svcId=${svcId}`);
    }

    const[data,setData]=useState('');

    useEffect(()=>{
      fetchData();
    },[]);

    const fetchData=()=>{
      axios.get(`${getFaq}`)
      .then(
        (response)=>{
          // console.log(response.data.response);
          setData(response.data.response);
        },
        (error)=>{
          console.log(error);
          toast.error("Something Went Wrong");
        }
      )
    }

  return (
    <div>
      <ToastContainer/>
      <section className="faq-main-sec-2">
        <div className="faq-logo-1">
          <img src={menubg} alt="ImgSrc"/>
        </div>
        <div className="faq-back-5">
          <button className="btn-bg-transparent" onClick={()=>{
            handleBack();
          }}>
            <img src={btn_textback2} alt="ImgSrc"/>
          </button>
        </div>

        <div className="faq-winner-data">
          <p>
            {" "}
            <div className="faq-parag-1">
              {parse(data)}
            </div>
          </p>
        </div>
      </section>
    </div>
  );
};
export default Faq;
