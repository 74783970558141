import React, { useEffect } from "react";
import { ToastContainer,toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import menubg from "../Data/assets/menubg-2.png";
import btn_textback2 from "../Data/assets/btn_textback2.png";
import play_more from "../Data/assets/play_more.png";
import "../CSS/category.css";
import { playMore } from "../API/api";
import axios from "axios";

const More = () => {

    useEffect(()=>{
        toast.success("Play More");
    },[]);

    const navigate=useNavigate();

    let uid;let gid;let svcId;
    const getLocalStorage = () => {
      uid = localStorage.getItem("uid");
      // console.log("uid from localStorage is ", uid);
  
      gid = localStorage.getItem("gid");
      // console.log("gid from localStorage is ", gid);  

      svcId=localStorage.getItem("svcId");
      console.log("svcId is ",svcId);
   };  

    const handleBack=()=>{
        getLocalStorage();
        navigate(`/?uid=${uid}&gid=${gid}&svcId=${svcId}`);
    }

    const handlePlay=()=>{
        //hit Request
        hitRequest();
    }

    const hitRequest=()=>{
        getLocalStorage();
        let request={uid:uid,gid:gid};
        axios.post(`${playMore}`,request)
        .then(
            (response)=>{
                console.log(response.data.response);
                toast.success("Please Visit After Some Time!!");
            },
            (error)=>{
                console.log(error);
                toast.error("Something Went Wrong");
            }
        )
    }

  return (
    <div>
    <ToastContainer/>
      <section className="category-main-sec-5">
        <div className="category-logo-1">
          <img src={menubg} alt="ImgSrc"/>
        </div>

        <div className="category-back-5">
          <button className="btn-bg-transparent" onClick={()=>{
            handleBack();
          }}>
            <img src={btn_textback2} alt="ImgSrc"/>
          </button>
        </div>
        
        <div className="category-ques-bg  category-menu-1">
        
          <div className="category-ques-bg-1">
            <button className="btn-bg-transparent" onClick={()=>{
                handlePlay();
            }}>
              <img src={play_more} alt="ImgSrc" />
            </button>
          </div>

        </div>
      </section>
    </div>
  );
};
export default More;